import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";

const MotionBox = motion(Box);

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const bgGradient = useColorModeValue(
    "linear(to-r, brand.500, accent.500)",
    "linear(to-r, brand.200, accent.200)"
  );
  const textColor = useColorModeValue("white", "gray.800");
  const shadowColor = useColorModeValue("rgba(0,0,0,0.1)", "rgba(255,255,255,0.1)");

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const eventDate = new Date(now.getFullYear(), 9, 5, 10, 0, 0); // October is month 9 (0-indexed)
      eventDate.setFullYear(now.getFullYear() + (eventDate < now ? 1 : 0)); // If this year's date has passed, use next year

      // Convert to PST
      const pstOffset = 420; // PST offset in minutes (7 hours)
      const localOffset = now.getTimezoneOffset();
      const timeDifference = (eventDate - now) + (pstOffset - localOffset) * 60 * 1000;

      if (timeDifference > 0) {
        setTimeLeft({
          days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((timeDifference / 1000 / 60) % 60),
          seconds: Math.floor((timeDifference / 1000) % 60),
        });
      } else {
        clearInterval(timer);
        setTimeLeft({});
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <Container maxW="container.lg" py={12}>
      <VStack spacing={8}>
        <MotionBox
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Flex justify="center" align="center" wrap="wrap" gap={6}>
            {Object.entries(timeLeft).map(([unit, value]) => (
              <AnimatePresence key={unit}>
                <MotionBox
                  variants={itemVariants}
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <VStack
                    bgGradient={bgGradient}
                    color={textColor}
                    borderRadius="2xl"
                    p={6}
                    minW="120px"
                    boxShadow={`0 10px 15px -3px ${shadowColor}`}
                    position="relative"
                    overflow="hidden"
                  >
                    <Text 
                      fontSize="4xl" 
                      fontWeight="bold"
                      textShadow="2px 2px 4px rgba(0,0,0,0.1)"
                    >
                      {value.toString().padStart(2, '0')}
                    </Text>
                    <Text 
                      fontSize="sm" 
                      textTransform="uppercase"
                      letterSpacing="wider"
                    >
                      {unit}
                    </Text>
                    <Box
                      position="absolute"
                      top="-20px"
                      left="-20px"
                      width="60px"
                      height="60px"
                      borderRadius="full"
                      bg="rgba(255,255,255,0.1)"
                    />
                  </VStack>
                </MotionBox>
              </AnimatePresence>
            ))}
          </Flex>
        </MotionBox>
      </VStack>
    </Container>
  );
};

export default CountdownTimer;