import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, keyframes, useColorModeValue } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { SunIcon, MoonIcon, StarIcon, createIcon } from '@chakra-ui/icons';

// Custom Heart Icon (unchanged)
const HeartIcon = createIcon({
  displayName: "HeartIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
    />
  ),
});

// Floating element animation (unchanged)
const floatingAnimation = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(10px, -10px) rotate(5deg); }
  50% { transform: translate(0, -20px) rotate(0eg); }
  75% { transform: translate(-10px, -10px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
`;

const FloatingElement = ({ children }) => (
  <Box
    animation={`${floatingAnimation} 6s ease-in-out infinite`}
    display="inline-block"
  >
    {children}
  </Box>
);

const MarqueeItem = ({ children }) => (
  <Box
    whiteSpace="nowrap"
    px={8}
    fontSize={["lg", "xl", "2xl"]}
    fontWeight="bold"
    color="white"
    _hover={{ color: "brand.200", transition: "color 0.3s ease" }}
  >
    {children}
  </Box>
);

const EnhancedMarquee = () => {
  const controls = useAnimation();
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const bgColor = useColorModeValue("brand.600", "brand.800");

  const marqueeItems = [
    { text: "Culver City Art Walk", icon: <StarIcon ml={2} color="yellow.400" /> },
    { text: "Discover Amazing Art", icon: <HeartIcon ml={2} color="red.400" /> },
    { text: "All Day", icon: (
      <>
        <SunIcon ml={2} mr={2} color="orange.400" />
        <MoonIcon color="blue.200" />
      </>
    )},
    { text: "Join the Creative Journey", icon: <StarIcon ml={2} color="purple.300" /> },
    { text: "Experience Culver City", icon: <HeartIcon ml={2} color="pink.400" /> },
  ];

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (containerWidth > 0) {
      controls.start({
        x: [-containerWidth / 2, -containerWidth],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 30,
            ease: "linear",
          },
        },
      });
    }
  }, [controls, containerWidth]);

  const repeatedItems = [...marqueeItems, ...marqueeItems, ...marqueeItems];

  return (
    <Box overflow="hidden" py={6} bg={bgColor} position="relative">
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgGradient={`linear(to-r, ${bgColor}, transparent 10%, transparent 90%, ${bgColor})`}
        pointerEvents="none"
        zIndex={1}
      />
      <Flex ref={containerRef}>
        <motion.div
          animate={controls}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {repeatedItems.map((item, index) => (
            <MarqueeItem key={index}>
              {item.text}
              <FloatingElement>
                {item.icon}
              </FloatingElement>
            </MarqueeItem>
          ))}
        </motion.div>
      </Flex>
    </Box>
  );
};

export default EnhancedMarquee;