import React, { useRef, useEffect } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";

const VerticalVideo = () => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const videoUrl =
    "https://sybassets.s3.us-west-1.amazonaws.com/Art_District.mp4";
  const videoRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const playVideos = () => {
      videoRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        }
      });
    };

    videoRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.addEventListener("loadedmetadata", playVideos);
      }
    });

    // Attempt to play videos immediately
    playVideos();

    return () => {
      videoRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener("loadedmetadata", playVideos);
        }
      });
    };
  }, []);

  const VideoBox = ({ width, height, brightness, videoRef }) => (
    <Box
      width={width}
      height={height}
      position="relative"
      overflow="hidden"
      filter={`brightness(${brightness})`}
      boxShadow="2xl"
      borderRadius="lg"
      transition="all 0.3s"
      _hover={{ transform: "scale(1.05)", zIndex: 1 }}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        loop
        muted
        playsInline
        autoPlay
        style={{
          position: "absolute",
          width: "auto",
          height: "100%",
          objectFit: "contain",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />
    </Box>
  );

  const displayMode = useBreakpointValue({ base: "mobile", md: "desktop" });

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      py={8}
      minHeight="80vh"
    >
      {displayMode === "desktop" ? (
        <>
          <VideoBox
            width="20%"
            height="60vh"
            brightness="0.6"
            videoRef={videoRefs[0]}
          />
          <VideoBox
            width="25%"
            height="70vh"
            brightness="1"
            videoRef={videoRefs[1]}
            style={{ margin: "0 20px", zIndex: 2 }}
          />
          <VideoBox
            width="20%"
            height="60vh"
            brightness="0.6"
            videoRef={videoRefs[2]}
          />
        </>
      ) : (
        <VideoBox
          width="80%"
          height="70vh"
          brightness="1"
          videoRef={videoRefs[1]}
        />
      )}
    </Flex>
  );
};

export default VerticalVideo;
