import React from "react";
import {
  Box,
  Flex,
  Button,
  useColorMode,
  IconButton,
  Image,
  Container,
  Tooltip,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  keyframes,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SunIcon, MoonIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";

const rotateLight = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const WorkWithUsButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/work-with-us")}
      colorScheme="pink"
      variant="solid"
      size="md"
      fontWeight="bold"
      borderRadius="full"
      px={6}
      position="relative"
      overflow="hidden"
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: "lg",
      }}
      _before={{
        content: '""',
        position: "absolute",
        top: "-50%",
        left: "-50%",
        width: "200%",
        height: "200%",
        background:
          "radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%)",
        animation: `${rotateLight} 4s linear infinite`,
      }}
      sx={{
        "&:hover::before": {
          animation: `${rotateLight} 2s linear infinite`,
        },
      }}
    >
      Work With Us
    </Button>
  );
};

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <Box
        as={motion.nav}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        bg={
          colorMode === "light" ? "rgba(255,255,255,0.8)" : "rgba(26,32,44,0.8)"
        }
        color={colorMode === "light" ? "gray.800" : "white"}
        backdropFilter="blur(10px)"
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={10}
      >
        <Container maxW="container.xl">
          <Flex py={5} alignItems="center" justifyContent="space-between">
            <Image
              src="https://ik.imagekit.io/mbhxghyf1m2/Pink%20Aesthetic%20Hearts%20Girl%20boss%20Logo_AUArDNbaP.png"
              alt="Stay Retro"
              height="70px"
              width="auto"
              cursor="pointer"
              onClick={() => navigate("/")}
            />
            <Flex alignItems="center">
              <Box
                display={{ base: "none", md: "flex" }}
                alignItems="center"
                mr={4}
              >
                <Button onClick={() => navigate("/")} variant="ghost" mr={2}>
                  HOME
                </Button>
                <Button
                  onClick={() => navigate("/about")}
                  variant="ghost"
                  mr={2}
                >
                  ABOUT
                </Button>
                <Button
                  onClick={() => navigate("/entertainment")}
                  variant="ghost"
                  mr={2}
                >
                  ENTERTAINMENT
                </Button>

                <WorkWithUsButton />
              </Box>
              <Tooltip label="Toggle color mode">
                <IconButton
                  icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  onClick={toggleColorMode}
                  variant="ghost"
                  aria-label="Toggle color mode"
                  mr={2}
                />
              </Tooltip>
              <Tooltip label="Menu">
                <IconButton
                  icon={<HamburgerIcon />}
                  onClick={onOpen}
                  variant="ghost"
                  aria-label="Open menu"
                  display={{ base: "flex", md: "none" }}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Container>
      </Box>

      {/* Mobile Menu Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Button onClick={() => handleNavigation("/")}>HOME</Button>
              <Button onClick={() => handleNavigation("/about")}>ABOUT</Button>
              <Button onClick={() => handleNavigation("/entertainment")}>
                ENTERTAINMENT
              </Button>
              <Button onClick={() => handleNavigation("/contact")}>
                CONTACT
              </Button>
              <WorkWithUsButton />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
