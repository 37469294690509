import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  SimpleGrid,
  useColorModeValue,
  Icon,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaHandshake,
  FaStore,
  FaUsers,
  FaPalette,
  FaMusic,
  FaUtensils,
  FaArrowRight,
  FaBiking,
} from "react-icons/fa";

const MotionBox = motion(Box);

const OptionCard = ({
  title,
  description,
  buttonText,
  icon,
  onClick,
  link,
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("purple.200", "purple.500");

  return (
    <MotionBox
      whileHover={{ scale: 1.05, rotate: 1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
      bg={bgColor}
      borderWidth="2px"
      borderColor={borderColor}
      borderRadius="xl"
      p={8}
      height="100%"
      boxShadow="xl"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      cursor="pointer"
      onClick={onClick}
    >
      <VStack spacing={6} align="stretch">
        <Icon as={icon} boxSize={12} color="purple.500" />
        <Heading as="h3" size="lg">
          {title}
        </Heading>
        <Text fontSize="md">{description}</Text>
      </VStack>
      <Button
        as={link ? "a" : "button"}
        href={link}
        target={link ? "_blank" : undefined}
        rel={link ? "noopener noreferrer" : undefined}
        colorScheme="purple"
        size="lg"
        mt={8}
        rightIcon={<FaArrowRight />}
        _hover={{ transform: "translateY(-2px)", boxShadow: "2xl" }}
      >
        {buttonText}
      </Button>
    </MotionBox>
  );
};

const FloatingIcon = ({ icon: IconComponent, top, left, delay }) => (
  <MotionBox
    position="absolute"
    animate={{
      y: [0, -20, 0],
      rotate: [0, 10, -10, 0],
    }}
    transition={{
      duration: 5,
      repeat: Infinity,
      repeatType: "reverse",
      delay,
    }}
    top={top}
    left={left}
  >
    <Icon as={IconComponent} boxSize={16} color="purple.400" opacity={0.4} />
  </MotionBox>
);

const WorkWithUs = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({});

  const handleCardClick = (title) => {
    setModalContent({ title });
    onOpen();
  };

  return (
    <Box bg={bgColor} minHeight="100vh" position="relative" overflow="hidden">
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        height="300px"
        opacity={0.3}
      />

      <FloatingIcon icon={FaPalette} top="25%" left="5%" delay={0} />
      <FloatingIcon icon={FaMusic} top="40%" left="90%" delay={1} />
      <FloatingIcon icon={FaUtensils} top="70%" left="15%" delay={2} />

      <Container maxW="container.xl" pt={40} pb={20}>
        <VStack spacing={20}>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Heading
              as="h1"
              size="4xl"
              textAlign="center"
              bgGradient="linear(to-r, purple.400, pink.400, yellow.400)"
              bgClip="text"
              letterSpacing="tight"
              mb={6}
            >
              Work With Us
            </Heading>
            <Text
              fontSize="2xl"
              textAlign="center"
              maxW="3xl"
              mx="auto"
              color={textColor}
            >
              Be part of the vibrant Culver City Art Walk! Join us in creating
              an unforgettable experience for our community and visitors alike.
            </Text>
          </MotionBox>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} width="100%">
            <AnimatePresence>
              <MotionBox
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <OptionCard
                  title="Sponsors"
                  description="Showcase your brand at the Art Walk. Reach a diverse audience and be part of this vibrant cultural event."
                  buttonText="Partner With Us"
                  icon={FaHandshake}
                  link="https://docs.google.com/forms/d/e/1FAIpQLScrPc_G3lGVPkx-zC-on_tS5vZirZDmL-TVmztKGe-tq7Obxw/viewform"
                />
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <OptionCard
                  title="Vendor Sign-Up"
                  description="Showcase your products or services. Join our community of vendors and connect with art enthusiasts and locals."
                  buttonText="Sign Up as Vendor"
                  icon={FaStore}
                  link="https://form.jotform.com/240574630991158"
                />
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <OptionCard
                  title="Volunteer"
                  description="Be part of the team that brings the Art Walk to life. Gain valuable experience and contribute to your community."
                  buttonText="Volunteer Now"
                  icon={FaUsers}
                  link="https://docs.google.com/forms/d/e/1FAIpQLSfF4rpkRAINbkPb_EWW16ceQHP-4CrBNLvM5X0kMZ51yagx3Q/viewform"
                />
              </MotionBox>
            </AnimatePresence>
          </SimpleGrid>
        </VStack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalContent.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input placeholder="Your name" />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input placeholder="Your email" />
              </FormControl>
              <FormControl>
                <FormLabel>Message</FormLabel>
                <Textarea placeholder="Tell us about your interest in the Art Walk" />
              </FormControl>
              <Button colorScheme="purple" width="full">
                Submit
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WorkWithUs;
