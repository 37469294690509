import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Badge,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

// Categorize vendors
const vendorCategories = {
  "Art & Photography": [
    "AFROCASIAN",
    "ALON GOLDSMITH PHOTOGRAPHY",
    "ANDREA CIMINI PHOTOGRAPHY",
    "ART BY CAROLE",
    "ART BY THE 3RD",
    "ARTHUR POCHON FINE ART",
    "CADFAB CREATIVE",
    "CLENN DESIGNS LLC",
    "EZOLAND",
    "GIGO",
    "JOANNA SALA FINE ART",
    "MADISON KIM",
    "NOAH GOTTLIEB ART",
    "PAINTNFLOW",
    "PATRICK HAEMMERLEIN ART",
    "REISIG AND TAYLOR CONTEMPORARY",
    "RICHARD ROWNAK",
    "SACHIKO BRADLEY ARTIST",
    "SCHMIDLI BACKDROPS",
    "SKINS LA",
    "STRAUSS PHOTOWORKS",
    "VINTAGE MENU ART",
  ],
  Jewelry: [
    "ANGELINA'S JEWELRY COLLECTION",
    "BOXWOOD-FASHION",
    "CINDY T JEWELRY",
    "GOGOLHOUS",
    "HANNAH FROST JEWELRY",
    "JUJU DESIGN LA",
    "LA MENO",
    "LONDON MANORI",
    "MADEDESIGNJEWELRY",
    "MYSTIC REBEL",
    "PANECHE",
    "PORTOLIOS",
    "SPLURGE RIA",
    "STUDIO GAV",
    "TOURMALINE JEWELER",
    "YERBA MYSTICA",
  ],
  "Apparel & Accessories": [
    "BLONDE PEACOCK",
    "BOLLA CO.",
    "DUSHYANT",
    "ELLERALI",
    "FRESHGENET",
    "FUNKY GAVROCHE",
    "KAZMIK GRACE",
    "MOOSE WITH ME",
    "NOHO CANDLE & CO. STUDIO",
    "ONE NINTH 1/9 STUDIO",
    "OSO PORTO",
    "PAMELA V.",
    "RECYCLICIOUS",
    "ROOM 34 ONLINE",
    "ROSY'S BOUTIQUE",
    "SINIPESA",
    "STORY SPARK",
    "THE POSTER LIST",
    "ZACRUU",
  ],
  "Home & Lifestyle": [
    "ASERELA",
    "CANYON COLLECTION",
    "CASSANDRA AUSTIN CERAMICS",
    "DUCKY CROCHET HOUSE",
    "EARTH MELODY",
    "ILIANAS ARTS",
    "KMADE DELICES DE KLODY",
    "NATIVIDAD ROMERO",
    "PATTRASHOME",
    "PLANTING HOPE LA",
    "QUYNH CERAMICRAFTS",
    "RITUAL GYPSY",
    "SAN PEDRO SOAP WORKS",
    "SPARKS GLASS ART",
    "THE GLOBAL TRUNK",
    "THE WHOLE 9",
    "TO LIVE A COLORFUL LIFE",
  ],
  "Non-Profit & Community": [
    "ANTIOCH UNIVERSITY CCITY",
    "BIKE CULVER CITY",
    "CC ARTIST LAUREATE KATIE KRANZ",
    "CC ARTS FOUNDATION / CC ARTS",
    "CC HISTORICAL SOCIETY",
    "CC SISTER CITY COMMITTEE",
    "JAZZ HANDS FOR AUTISM",
    "ON THE EDGE DANCE STUDIO",
    "SCREENLAND 5K",
    "THE ACTOR'S GANG",
    "TING INTERNET",
    "UPSTANDER KIDS ANTI-BULLYING CAMP",
    "WALK 'N ROLLERS",
    "YOGASIX CULVER CITY",
  ],
  "Food & Drink": ["KMADE DELICES DE KLODY", "THE TAMALE FIESTA"],
  "Unique Experiences": [
    "ART LUMINOUS",
    "ELAIN PARTNOW'S HIDDEN TREE CREATURES",
    "MAYARI STUDIOS",
    "PLANT VENDOR - COMP SM TRADE",
    "RICKY CARICATURES",
    "ROCK & SOCK",
    "SHANAH DESIGN",
  ],
};

const VendorCard = ({ name }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <MotionBox
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      p={6}
      textAlign="center"
      boxShadow="lg"
      transition="all 0.3s"
    >
      <Text fontWeight="bold" fontSize="lg">
        {name}
      </Text>
    </MotionBox>
  );
};

const VendorsShowcase = () => {
  const [index, setIndex] = useState([0]);
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("brand.600", "brand.300");
  const accordionBgColor = useColorModeValue("white", "gray.700");

  return (
    <Box bg={bgColor} minHeight="100vh" py={20}>
      <Container maxW="container.xl">
        <VStack spacing={12}>
          <Box textAlign="center">
            <Heading
              as="h1"
              size="3xl"
              textAlign="center"
              mb={6}
              color={headingColor}
            >
              Discover Our Amazing Vendors
            </Heading>
            <Text fontSize="xl" textAlign="center" mb={8} maxW="3xl" mx="auto">
              Explore the diverse range of talented artists, craftspeople, and
              local businesses at the Culver City Art Walk.
            </Text>
          </Box>

          <Accordion
            allowMultiple
            index={index}
            onChange={setIndex}
            width="100%"
            borderRadius="xl"
            overflow="hidden"
            boxShadow="xl"
            bg={accordionBgColor}
          >
            {Object.entries(vendorCategories).map(
              ([category, vendors], catIndex) => (
                <AccordionItem key={category} border="none">
                  <AccordionButton
                    py={4}
                    _expanded={{ bg: headingColor, color: "white" }}
                  >
                    <Box flex="1" textAlign="left">
                      <Heading as="h2" size="lg">
                        {category}
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={8} pt={6}>
                    <SimpleGrid
                      columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                      spacing={6}
                    >
                      {vendors.map((vendor) => (
                        <VendorCard key={vendor} name={vendor} />
                      ))}
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
              )
            )}
          </Accordion>

          <Box mt={12} textAlign="center">
            <Badge
              colorScheme="brand"
              fontSize="xl"
              p={4}
              borderRadius="full"
              boxShadow="md"
            >
              {Object.values(vendorCategories).flat().length} Vendors in Total
            </Badge>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default VendorsShowcase;
