import React, { useState } from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  Flex,
  useColorModeValue,
  Container,
  Icon,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { FaGuitar, FaDrum, FaMicrophone } from "react-icons/fa";

const StageSchedule = ({ stageName, schedule }) => {
  const [isOpen, setIsOpen] = useState(false);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const accentColor = useColorModeValue("brand.600", "brand.300");
  const itemBgColor = useColorModeValue("gray.50", "gray.700");

  const getIcon = (act) => {
    if (act.toLowerCase().includes("dj")) return FaMicrophone;
    if (act.toLowerCase().includes("band")) return FaDrum;
    return FaGuitar;
  };

  return (
    <Box
      bg={bgColor}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      transition="all 0.3s"
      _hover={{ boxShadow: "lg" }}
    >
      <Flex
        as="button"
        onClick={() => setIsOpen(!isOpen)}
        width="100%"
        py={4}
        px={4}
        bg={accentColor}
        color="white"
        alignItems="center"
        justifyContent="space-between"
        _hover={{ bg: "brand.700" }}
        transition="all 0.3s"
      >
        <Heading size="md">{stageName}</Heading>
        <Icon
          as={isOpen ? ChevronUpIcon : ChevronDownIcon}
          w={6}
          h={6}
          transition="transform 0.3s"
          transform={isOpen ? "rotate(180deg)" : "rotate(0)"}
        />
      </Flex>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <VStack spacing={2} align="stretch" p={4}>
              {schedule.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <Flex
                    p={3}
                    bg={itemBgColor}
                    borderRadius="md"
                    boxShadow="sm"
                    justifyContent="space-between"
                    alignItems="center"
                    _hover={{ boxShadow: "md", transform: "translateY(-1px)" }}
                    transition="all 0.2s"
                  >
                    <Flex alignItems="center">
                      <Icon
                        as={getIcon(item.act)}
                        color={accentColor}
                        w={5}
                        h={5}
                        mr={3}
                      />
                      <Box>
                        <Text fontWeight="bold" color={textColor} fontSize="md">
                          {item.act}
                        </Text>
                        <Text fontSize="sm" color={accentColor}>
                          {item.time}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </motion.div>
              ))}
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

const EnhancedStageSchedules = () => {
  const sherbourneSchedule = [
    { time: "10:30am - 11:30am", act: "DJ Medit8" },
    { time: "11:30am - 12:25pm", act: "Fairfax Station" },
    { time: "12:25pm - 12:45pm", act: "DJ Medit8" },
    { time: "12:45pm - 1:40pm", act: "The Jumps" },
    { time: "1:40pm - 2:00pm", act: "DJ Medit8" },
    { time: "2:00pm - 2:55pm", act: "FlyZulu" },
    { time: "2:55pm - 3:15pm", act: "DJ Medit8" },
    { time: "3:15pm - 4:10pm", act: "Sadubas" },
    { time: "4:10pm - 4:30pm", act: "DJ Medit8" },
    { time: "4:30pm - 5:25pm", act: "Cesar Alan" },
  ];

  const mcmanusSchedule = [
    { time: "10:30am - 11:30am", act: "DJ Cookie Jar" },
    { time: "11:30am - 12:25pm", act: "Double Batch Daddy" },
    { time: "12:25pm - 12:45pm", act: "DJ Cookie Jar" },
    { time: "12:45pm - 1:40pm", act: "The Front Yard" },
    { time: "1:40pm - 2:00pm", act: "DJ Cookie Jar" },
    { time: "2:00pm - 2:55pm", act: "Dagnasterpus" },
    { time: "2:55pm - 3:15pm", act: "DJ Cookie Jar" },
    { time: "3:15pm - 4:10pm", act: "Los Vecinos" },
    { time: "4:10pm - 4:30pm", act: "DJ Cookie Jar" },
    { time: "4:30pm - 5:25pm", act: "Top Shelf Brass Band" },
  ];

  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Box bg={bgColor} py={8}>
      <Container maxW="container.xl" pb={12}>
        <Flex direction={["column", "column", "row"]} width="100%" gap={6}>
          <Box flex={1}>
            <StageSchedule
              stageName="Sherbourne Stage Schedule"
              schedule={sherbourneSchedule}
            />
          </Box>
          <Box flex={1}>
            <StageSchedule
              stageName="McManus Stage Schedule"
              schedule={mcmanusSchedule}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default EnhancedStageSchedules;
