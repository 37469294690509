import React from "react";
import {
  Box,
  Container,
  Heading,
  Grid,
  Image,
  Text,
  Button,
  useColorMode,
  VStack,
  HStack,
  Icon,
  Badge,
  Tooltip,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { CalendarIcon, TimeIcon, ExternalLinkIcon } from "@chakra-ui/icons";

const MotionBox = motion(Box);

const AnimatedTitle = () => (
  <Box
    position="relative"
    width="100%"
    height={{ base: "120px", md: "180px" }}
    overflow="hidden"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 600 180"
      preserveAspectRatio="xMidYMid meet"
    >
      <motion.path
        d="M100,140 Q300,180 500,140"
        fill="none"
        stroke="url(#gradient)"
        strokeWidth="4"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: [0, 1, 1, 0] }}
        transition={{ duration: 4, times: [0, 0.4, 0.6, 1], repeat: Infinity }}
      />
      <motion.path
        d="M100,145 Q300,100 500,145"
        fill="none"
        stroke="url(#gradient)"
        strokeWidth="4"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: [0, 1, 1, 0] }}
        transition={{
          duration: 4,
          times: [0, 0.4, 0.6, 1],
          delay: 0.5,
          repeat: Infinity,
        }}
      />
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#6B46C1" />
          <stop offset="100%" stopColor="#D53F8C" />
        </linearGradient>
      </defs>
    </svg>
    <Flex
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Heading
          as="h2"
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          bgGradient="linear(to-r, purple.500, pink.500)"
          bgClip="text"
          textAlign="center"
        >
          Entertainment
        </Heading>
      </motion.div>
    </Flex>
    {[...Array(20)].map((_, i) => (
      <motion.div
        key={i}
        style={{
          position: "absolute",
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: i % 2 === 0 ? "#6B46C1" : "#D53F8C",
        }}
        animate={{
          scale: [0, 1, 0],
          opacity: [0, 1, 0],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "loop",
          delay: Math.random() * 2,
        }}
      />
    ))}
  </Box>
);

const UpcomingEvents = () => {
  const { colorMode } = useColorMode();
  const cardColumns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

  const events = [
    {
      id: 1,
      name: "Art Festival",
      image: "https://ik.imagekit.io/mbhxghyf1m2/PA060142_DY86mPz7Or.jpg",
      category: "FESTIVAL",
      description: "Experience a day filled with creativity and inspiration.",
    },
    {
      id: 2,
      name: "Live Performances",
      image: "https://ik.imagekit.io/mbhxghyf1m2/PA060058_HrqmXKI04.jpg",
      category: "MUSIC",
      description: "Enjoy captivating performances throughout the day.",
    },
    {
      id: 3,
      name: "Food & Beverage",
      image:
        "https://ik.imagekit.io/mbhxghyf1m2/DSC07895_Fjf16jWVC.jpg?updatedAt=1722017438178",
      category: "CULINARY",
      description: "Savor delicious treats and refreshing beverages.",
    },
  ];

  return (
    <Box
      bg={colorMode === "light" ? "gray.100" : "gray.800"}
      py={{ base: 8, md: 24 }}
    >
      <Container maxW="container.xl">
        <VStack spacing={{ base: 6, md: 16 }}>
          <AnimatedTitle />
          <Grid
            templateColumns={[`repeat(${cardColumns}, 1fr)`]}
            gap={{ base: 4, md: 8 }}
          >
            <AnimatePresence>
              {events.map((event) => (
                <MotionBox
                  key={event.id}
                  borderWidth={1}
                  borderRadius="lg"
                  overflow="hidden"
                  bg={colorMode === "light" ? "white" : "gray.700"}
                  boxShadow="md"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  whileHover={{ y: -5, boxShadow: "xl" }}
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <Box position="relative">
                    <Image
                      src={event.image}
                      alt={event.name}
                      h={{ base: "150px", md: "200px" }}
                      w="100%"
                      objectFit="cover"
                      transition="transform 0.3s ease-in-out"
                      _hover={{ transform: "scale(1.05)" }}
                    />
                    <Badge
                      position="absolute"
                      top={2}
                      right={2}
                      colorScheme="purple"
                      fontSize="0.8em"
                      borderRadius="full"
                      px={2}
                    >
                      {event.category}
                    </Badge>
                  </Box>
                  <Flex direction="column" p={{ base: 3, md: 6 }} flex={1}>
                    <Heading as="h3" size="md" lineHeight="shorter" mb={2}>
                      {event.name}
                    </Heading>
                    <Box
                      height={{ base: "auto", md: "48px" }}
                      mb={{ base: 2, md: 4 }}
                    >
                      <Text
                        fontSize={{ base: "xs", md: "sm" }}
                        noOfLines={{ base: 3, md: 2 }}
                      >
                        {event.description}
                      </Text>
                    </Box>
                    <Button
                      as={motion.button}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      mt={{ base: 2, md: "auto" }}
                      colorScheme="purple"
                      size="sm"
                      width="full"
                      rightIcon={<ExternalLinkIcon />}
                      onClick={() => (window.location.href = "/entertainment")}
                    >
                      Learn More
                    </Button>
                  </Flex>
                </MotionBox>
              ))}
            </AnimatePresence>
          </Grid>
        </VStack>
      </Container>
    </Box>
  );
};

export default UpcomingEvents;
