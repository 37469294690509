import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Grid,
  GridItem,
  Image,
  keyframes,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const FadeInWhenVisible = ({ children }) => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      {children}
    </MotionBox>
  );
};

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const ModernLiveMusicSection = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const accentColor = useColorModeValue("brand.600", "brand.300");

  return (
    <Box py={20} bg={bgColor} position="relative">
      <Container maxW="container.xl">
        <VStack spacing={12} align="stretch">
          <FadeInWhenVisible>
            <VStack spacing={4} textAlign="center">
              <Heading
                as="h2"
                size="3xl"
                color={accentColor}
                fontWeight="extrabold"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                Live Music Experience{" "}
                <Box
                  as="span"
                  animation={`${bounce} 1s ease-in-out infinite`}
                  display="inline-block"
                  ml={2}
                >
                  🎵
                </Box>
              </Heading>
            </VStack>
          </FadeInWhenVisible>
        </VStack>
      </Container>
    </Box>
  );
};

export default ModernLiveMusicSection;