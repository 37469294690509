import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  useColorMode,
  VStack,
  Link,
  SimpleGrid,
  useBreakpointValue,
  AspectRatio,
} from "@chakra-ui/react";
import { motion, useInView } from "framer-motion";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const SponsorSection = () => {
  const { colorMode } = useColorMode();
  const [hoveredSponsor, setHoveredSponsor] = useState(null);
  const containerRef = React.useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "-100px" });

  const sponsors = [
    {
      name: "ArtCo",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20White%20Square%20Interior%20Designer%20Logo_aTgwHH99Q.png",
      description: "Empowering artists with innovative tools and spaces.",
      tagline: "Innovate. Create. Inspire.",
    },
    {
      name: "PaintPals",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20and%20White%20Minimalist%20Professional%20Initial%20Logo%20(1)_47ilhSCRF.png",
      description:
        "Connecting artists worldwide through collaborative platforms.",
      tagline: "Collaboration Across Canvases",
    },
    {
      name: "CreativeBrush",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20White%20Modern%20Handwritten%20Square%20Studio%20Logo%20(3)_FYGxaqQT8.png",
      description: "Revolutionizing digital art with cutting-edge software.",
      tagline: "Digital Dreams, Real Art",
    },
    {
      name: "GalleryGurus",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Gray%20and%20Black%20Simple%20Studio%20Logo_MedfDB4bvN.png",
      description: "Curating extraordinary art experiences for all.",
      tagline: "Curating Wonder, Inspiring Awe",
    },
    {
      name: "ArtisanAlliance",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20White%20Square%20Interior%20Designer%20Logo_aTgwHH99Q.png",
      description:
        "Supporting local artisans and preserving traditional crafts.",
      tagline: "Preserving Tradition, Empowering Artisans",
    },
    {
      name: "CanvasConnect",
      logo: "https://ik.imagekit.io/mbhxghyf1m2/Black%20and%20White%20Minimalist%20Professional%20Initial%20Logo%20(1)_47ilhSCRF.png",
      description:
        "Bridging the gap between emerging artists and art enthusiasts.",
      tagline: "Connecting Creativity",
    },
  ];

  const columnCount = useBreakpointValue({ base: 1, md: 2, lg: 3 });

  return (
    <Box
      ref={containerRef}
      bg={colorMode === "light" ? "gray.50" : "gray.900"}
      py={20}
      position="relative"
      overflow="hidden"
    >
      {/* Subtle background pattern */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        opacity={0.05}
        backgroundImage={`url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`}
      />

      <Container maxW="container.xl" position="relative" zIndex={1}>
        <VStack spacing={12} align="stretch">
          {/* <MotionHeading 
            size="2xl" 
            textAlign="center"
            bgGradient="linear(to-r, brand.500, accent.500)"
            bgClip="text"
            initial={{ opacity: 0, y: -20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Our Visionary Sponsors
          </MotionHeading> */}
          <MotionText
            fontSize="xl"
            textAlign="center"
            maxW="2xl"
            mx="auto"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            Empowering creativity and fostering innovation in the art community.
          </MotionText>
          <SimpleGrid columns={columnCount} spacing={8} w="full">
            {sponsors.map((sponsor, index) => (
              <MotionBox
                key={sponsor.name}
                initial={{ opacity: 0, y: 20 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.1 * index }}
                whileHover={{ y: -5 }}
                onMouseEnter={() => setHoveredSponsor(sponsor)}
                onMouseLeave={() => setHoveredSponsor(null)}
              >
                <AspectRatio ratio={1}>
                  <Box
                    bg={colorMode === "light" ? "white" : "gray.800"}
                    p={6}
                    borderRadius="xl"
                    boxShadow="xl"
                    transition="all 0.3s"
                    _hover={{ boxShadow: "2xl" }}
                    position="relative"
                    overflow="hidden"
                  >
                    <VStack spacing={4} align="stretch" h="full">
                      <Box
                        flex={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <MotionBox
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.5 }}
                          width="100%"
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img
                            src={sponsor.logo}
                            alt={sponsor.name}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "80%",
                              objectFit: "contain",
                              transition: "transform 0.3s ease-in-out",
                            }}
                          />
                          <MotionText
                            fontSize="sm"
                            fontStyle="italic"
                            textAlign="center"
                            mt={2}
                            color={
                              colorMode === "light" ? "gray.600" : "gray.300"
                            }
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                          >
                            {sponsor.tagline}
                          </MotionText>
                        </MotionBox>
                      </Box>
                      <VStack spacing={2} align="start" flex={1}>
                        <Text fontWeight="bold" fontSize="lg">
                          {sponsor.name}
                        </Text>
                        <Text fontSize="sm" noOfLines={2}>
                          {sponsor.description}
                        </Text>
                      </VStack>
                      <Link
                        href="#"
                        color="brand.500"
                        fontWeight="medium"
                        fontSize="sm"
                        _hover={{ textDecoration: "none", color: "brand.600" }}
                        alignSelf="flex-start"
                        mt="auto"
                      >
                        Learn More
                      </Link>
                    </VStack>
                  </Box>
                </AspectRatio>
              </MotionBox>
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default SponsorSection;
