// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      50: "#F0E4FF",
      100: "#D1BEFF",
      200: "#B088FF",
      300: "#9355FF",
      400: "#7722FF",
      500: "#5A00E6",
      600: "#4700B3",
      700: "#350080",
      800: "#23004D",
      900: "#11001A",
    },
    accent: {
      50: "#FFE4F0",
      100: "#FFB8D1",
      200: "#FF8CB3",
      300: "#FF6095",
      400: "#FF3477",
      500: "#FF0859",
      600: "#CC0047",
      700: "#990035",
      800: "#660023",
      900: "#330012",
    },
  },
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
    extrabold: 800,
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'gray.900' : 'gray.50',
        color: props.colorMode === 'dark' ? 'white' : 'gray.900',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'full',
      },
      sizes: {
        xl: {
          h: 16,
          minW: 16,
          fontSize: 'xl',
          px: 8,
        },
      },
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'brand.400' : 'brand.500',
          color: 'white',
          _hover: {
            bg: props.colorMode === 'dark' ? 'brand.500' : 'brand.600',
          },
        }),
        outline: (props) => ({
          borderColor: props.colorMode === 'dark' ? 'brand.400' : 'brand.500',
          color: props.colorMode === 'dark' ? 'brand.400' : 'brand.500',
          _hover: {
            bg: props.colorMode === 'dark' ? 'brand.800' : 'brand.50',
          },
        }),
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'extrabold',
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});

export default theme;